<template>
  <div>
    <!--MODAL ADD/EDIT-->
    <b-modal
      id="add-edit-users"
      ref="add-edit-users"
      size="lg"
      :title="getTitle()"
      :busy="isModalBusy"
      :ok-disabled="$v.$invalid"
      :ok-title="modalName === 'add' ? $t('table.add') : $t('table.edit')"
      :cancel-title="$t('table.cancel')"
      :hide-footer="!canEdit"
      @show="clearFields"
      @shown="loadProps"
      @hidden="clearInEnd"
      @ok.prevent="showModalUsersConfirm"
    >
      <form v-if="!busy" @submit.stop.prevent="checkIfValidThenEnter">
        <input type="submit" value="Submit" class="hidden-submit" />
        <div class="d-flex justify-content-between">
          <b-form-group style="width: 49%" :label="$t('table.writeName')" label-for="input-1-add-name">
            <b-form-input
              id="input-1-add-name"
              v-model="name"
              type="text"
              :placeholder="$t('table.writeName')"
              required
              autofocus
              :disabled="!canEdit"
            ></b-form-input>
          </b-form-group>
          <b-form-group style="width: 49%" :label="$t('table.writeLogin')" label-for="input-2-add-login">
            <b-form-input id="input-2-add-login" v-model="login" type="text" :placeholder="$t('table.writeLogin')" required :disabled="!canEdit"></b-form-input>
          </b-form-group>
        </div>

        <div class="d-flex justify-content-between">
          <b-form-group style="width: 49%" :label="$t('table.writeEmail')" label-for="input-3-add-email">
            <b-form-input
              id="input-3-add-email"
              v-model="email"
              type="email"
              :placeholder="$t('table.writeEmail')"
              required
              :disabled="!canEdit"
            ></b-form-input>
          </b-form-group>
          <b-form-group style="width: 49%" :label="$t('table.writePassword')" label-for="input-password">
            <b-icon
              v-if="!isOpenPassword"
              style="position: absolute; transform: translate(345px, 12px); cursor: pointer"
              icon="eye"
              font-scale="1"
              @click="isOpenPassword = !isOpenPassword"
            ></b-icon>
            <b-icon
              v-if="isOpenPassword"
              style="position: absolute; transform: translate(345px, 12px); cursor: pointer"
              icon="eye-slash"
              font-scale="1"
              @click="isOpenPassword = !isOpenPassword"
            ></b-icon>
            <b-form-input
              id="input-password"
              v-model="password"
              :type="isOpenPassword ? 'password' : 'text'"
              :placeholder="$t('table.writePassword')"
              :required="modalName === 'edit' ? undefined : true"
              minlength="8"
              :disabled="!canEdit"
            ></b-form-input>
          </b-form-group>
        </div>

        <b-form-group :label="$t('table.chooseRole')" style="width: 49%">
          <MultiSelect v-model="role" :options="roles" :placeholder="$t('table.chooseRole')" :allow-empty="false" label="name" track-by="id"></MultiSelect>
        </b-form-group>
        <hr />
        <b-form-group v-if="role && (role.name === 'Chief' || role.name === 'Chief channel')" :label="$t('table.showWithSpecialDiscounts')" label-size="sm">
          <b-form-checkbox v-model="showWithSpecialDiscounts" class="mt-1 ml-1" switch> {{ $t('table.showWithSpecialDiscounts') }}</b-form-checkbox>
        </b-form-group>
        <!-- ENTITY PERMISSIONS -->
        <!-- SALESHOUSE -->
        <b-form-group
          v-if="
            role && (role.name === 'Account manager saleshouse' || role.name === 'Chief' || role.name === 'Chief channel' || role.name === 'Chief saleshouse')
          "
          style="width: 49%"
          class="mr-auto mb-4"
        >
          <h5 class="mb-2">{{ $t('table.salesHouse') }}</h5>
          <MultiSelect
            v-model="saleshouse"
            :options="salehousesList"
            :placeholder="$t('table.selectSaleshouses')"
            label="name"
            track-by="id"
            :disabled="!canEdit"
          ></MultiSelect>
        </b-form-group>
        <!-- AGENCY -->
        <template
          v-if="
            role &&
            (role.name === 'Account manager agency' ||
              role.name === 'Chief' ||
              role.name === 'Chief channel' ||
              role.name === 'Chief saleshouse' ||
              role.name === 'Account manager saleshouse')
          "
        >
          <div class="d-flex w-100 justify-content-between flex-wrap gap-2">
            <div class="w-100 d-flex gap-2">
              <h5 class="mb-0">{{ $t('sideBar.agencies') }}</h5>
              <b-form-checkbox
                v-if="role.name === 'Chief' || role.name === 'Chief channel' || role.name === 'Chief saleshouse' || role.name === 'Account manager saleshouse'"
                v-model="permissions.agencies.allowedAll"
                switch
                inline
                :disabled="!canEdit"
                @input="toggleAllowedAll(permissions.agencies, agenciesList)"
              >
                {{ $t('table.allowAll') }}
              </b-form-checkbox>
            </div>
            <div class="form-group mb-0 mr-auto" style="width: 49%">
              <label class="d-inline-block mr-3">{{ $t('table.allowedAgencies') }}</label>
              <b-table
                :items="permissions.agencies.allowedList"
                :fields="[
                  { key: 'selected', label: '—' },
                  { key: 'name', label: $t('table.name') },
                ]"
                responsive
                class="table-sm small permission-table border"
                :table-class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'"
                show-empty
                @row-clicked="selectAllowedAgency"
              >
                <template #cell(selected)="data">
                  <span v-if="data.item.rowSelected" aria-hidden="true">&check;</span>
                </template>
              </b-table>

              <p>{{ permissions.agencies.allowed.map((el) => el.name).join(', ') }}</p>
            </div>
          </div>
          <hr />
        </template>
        <!-- CHANNELS -->
        <template
          v-if="
            role &&
            (role.name === 'Account manager channel' ||
              role.name === 'Chief' ||
              role.name === 'Chief saleshouse' ||
              role.name === 'Chief channel' ||
              role.name === 'Account manager agency')
          "
        >
          <div class="d-flex justify-content-between flex-wrap gap-2">
            <div class="w-100 d-flex gap-2">
              <h5 class="mb-0">{{ $t('sideBar.channels') }}</h5>
              <b-form-checkbox
                v-if="role.name === 'Chief' || role.name === 'Chief channel' || role.name === 'Chief saleshouse' || role.name === 'Account manager agency'"
                v-model="permissions.channels.allowedAll"
                switch
                inline
                :disabled="!canEdit"
                @input="toggleAllowedAll(permissions.channels, channelsList)"
              >
                {{ $t('table.allowAll') }}
              </b-form-checkbox>
            </div>
            <div class="form-group mb-0 mr-auto" style="width: 49%">
              <label class="d-inline-block mr-3">{{ $t('table.allowedChannels') }}</label>
              <b-table
                :items="permissions.channels.allowedList"
                :fields="[
                  { key: 'selected', label: '—' },
                  { key: 'name', label: $t('table.name') },
                ]"
                responsive
                class="table-sm small permission-table border"
                :table-class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'"
                show-empty
                @row-clicked="selectAllowedChannel"
              >
                <template #cell(selected)="data">
                  <span v-if="data.item.rowSelected" aria-hidden="true">&check;</span>
                </template>
              </b-table>

              <p>{{ permissions.channels.allowed.map((el) => el.name).join(', ') }}</p>
            </div>
          </div>
          <hr />
        </template>
        <!-- ADVERTISERS -->
        <div class="d-flex justify-content-between flex-wrap gap-2">
          <h5 class="mb-0">{{ $t('table.advertisers') }}</h5>
          <b-form-checkbox
            v-model="permissions.advertisers.allowedAll"
            class="mr-auto"
            switch
            inline
            :disabled="!canEdit"
            @input="toggleAllowedAll(permissions.advertisers, advertisersList)"
          >
            {{ $t('table.allowAll') }}
          </b-form-checkbox>
          <MultiSelect
            v-model="permissions.advertisers.agency"
            :options="filterAgenciesList"
            :placeholder="$t('table.selectAgency')"
            :allow-empty="false"
            label="name"
            track-by="id"
            @input="loadAllowedAdvertisers"
          ></MultiSelect>
          <div class="form-group mb-0" style="width: 49%">
            <label class="d-inline-block mr-3">{{ $t('table.allowedAdvertisers') }}</label>
            <b-table
              :items="permissions.advertisers.allowedList"
              :fields="[
                { key: 'selected', label: '—' },
                { key: 'name', label: $t('table.name') },
              ]"
              responsive
              class="table-sm small permission-table border"
              :table-class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'"
              show-empty
              @row-clicked="selectAllowedAdvertiser"
            >
              <template #cell(selected)="data">
                <span v-if="data.item.rowSelected" aria-hidden="true">&check;</span>
              </template>
            </b-table>

            <p>{{ permissions.advertisers.allowed.map((el) => el.name).join(', ') }}</p>
          </div>
          <div class="form-group mb-0" style="width: 49%">
            <label class="d-inline-block mr-3">{{ $t('table.deniedAdvertisers') }}</label>
            <b-table
              :items="permissions.advertisers.deniedList"
              :fields="[
                { key: 'selected', label: '—' },
                { key: 'name', label: $t('table.name') },
              ]"
              responsive
              class="table-sm small permission-table border"
              :table-class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'"
              show-empty
              @row-clicked="selectDeniedAdvertiser"
            >
              <template #cell(selected)="data">
                <span v-if="data.item.rowSelected" aria-hidden="true">&check;</span>
              </template>
            </b-table>

            <p>{{ permissions.advertisers.denied.map((el) => el.name).join(', ') }}</p>
          </div>
        </div>
        <hr />
        <!-- BRANDS -->
        <div class="d-flex justify-content-between flex-wrap gap-2">
          <h5 class="mb-0">{{ $t('table.brands') }}</h5>
          <b-form-checkbox
            v-model="permissions.brands.allowedAll"
            class="mr-auto"
            switch
            inline
            :disabled="!canEdit"
            @input="toggleAllowedAll(permissions.brands, brandsList)"
          >
            {{ $t('table.allowAll') }}
          </b-form-checkbox>
          <div class="d-flex gap-2 w-100 justify-content-between">
            <MultiSelect
              v-model="permissions.brands.agency"
              :options="filterAgenciesList"
              :placeholder="$t('table.selectAgency')"
              label="name"
              track-by="id"
              style="width: 49%"
              @input="loadAdvertisersForBrand"
            ></MultiSelect>
            <MultiSelect
              v-model="permissions.brands.advertiser"
              :options="permissions.brands.advertisersList"
              :placeholder="$t('table.selectAdvertiser')"
              label="name"
              track-by="id"
              style="width: 49%"
              @input="loadBrands"
            ></MultiSelect>
          </div>
          <div class="form-group mb-0" style="width: 49%">
            <label class="d-inline-block mr-3">{{ $t('table.allowedBrands') }}</label>
            <b-table
              :items="permissions.brands.allowedList"
              :fields="[
                { key: 'selected', label: '—' },
                { key: 'name', label: $t('table.name') },
              ]"
              responsive
              class="table-sm small permission-table border"
              :table-class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'"
              show-empty
              @row-clicked="selectAllowedBrand"
            >
              <template #cell(selected)="data">
                <span v-if="data.item.rowSelected" aria-hidden="true">&check;</span>
              </template>
            </b-table>

            <p>{{ permissions.brands.allowed.map((el) => el.name).join(', ') }}</p>
          </div>

          <div class="form-group mb-0" style="width: 49%">
            <label class="d-inline-block mr-3">{{ $t('table.deniedBrands') }}</label>
            <b-table
              :items="permissions.brands.deniedList"
              :fields="[
                { key: 'selected', label: '—' },
                { key: 'name', label: $t('table.name') },
              ]"
              responsive
              class="table-sm small permission-table border"
              :table-class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'"
              show-empty
              @row-clicked="selectDeniedBrand"
            >
              <template #cell(selected)="data">
                <span v-if="data.item.rowSelected" aria-hidden="true">&check;</span>
              </template>
            </b-table>

            <p>{{ permissions.brands.denied.map((el) => el.name).join(', ') }}</p>
          </div>
        </div>
        <hr />
        <!-- PROJECTS -->
        <div class="d-flex justify-content-between flex-wrap gap-2">
          <h5 class="mb-0">{{ $t('table.projects') }}</h5>
          <b-form-checkbox
            v-model="permissions.projects.allowedAll"
            class="mr-auto"
            switch
            inline
            :disabled="!canEdit"
            @input="toggleAllowedAll(permissions.projects, projects)"
          >
            {{ $t('table.allowAll') }}
          </b-form-checkbox>
          <MultiSelect
            v-model="permissions.projects.agency"
            :options="filterAgenciesList"
            :placeholder="$t('table.selectAgency')"
            :allow-empty="false"
            label="name"
            track-by="id"
            @input="loadProjects"
          ></MultiSelect>
          <div class="form-group mb-0" style="width: 49%">
            <label class="d-inline-block mr-3">{{ $t('table.allowedProjects') }}</label>
            <b-table
              :items="permissions.projects.allowedList"
              :fields="[
                { key: 'selected', label: '—' },
                { key: 'name', label: $t('table.name') },
              ]"
              responsive
              class="table-sm small permission-table border"
              :table-class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'"
              show-empty
              @row-clicked="selectAllowedProject"
            >
              <template #cell(selected)="data">
                <span v-if="data.item.rowSelected" aria-hidden="true">&check;</span>
              </template>
            </b-table>

            <p>{{ permissions.projects.allowed.map((el) => el.name).join(', ') }}</p>
          </div>

          <div class="form-group mb-0" style="width: 49%">
            <label class="d-inline-block mr-3">{{ $t('table.deniedProjects') }}</label>
            <b-table
              :items="permissions.projects.deniedList"
              :fields="[
                { key: 'selected', label: '—' },
                { key: 'name', label: $t('table.name') },
              ]"
              responsive
              class="table-sm small permission-table border"
              :table-class="isThemeHeader === 'true' ? 'white-color' : 'dark-color'"
              show-empty
              @row-clicked="selectDeniedProject"
            >
              <template #cell(selected)="data">
                <span v-if="data.item.rowSelected" aria-hidden="true">&check;</span>
              </template>
            </b-table>

            <p>{{ permissions.projects.denied.map((el) => el.name).join(', ') }}</p>
          </div>
        </div>
      </form>
      <SpinnerLoader v-else :loading="busy === true ? 'loading' : 'success'" />
    </b-modal>

    <!-- CONFIRM ADD/EDIT -->
    <b-modal
      id="add-users-confirm"
      ref="add-users-confirm"
      size="sm"
      :title="modalName === 'add' ? $t('table.addNewUsers') : $t('table.editUsers')"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      :busy="isModalBusy"
      @ok.prevent="addEditUsers"
    >
      <p class="my-2">
        {{ modalName === 'add' ? $t('table.confirmAddUsers') : $t('table.confirmEditUsers') }} <span class="text-danger">{{ email }}</span>
        ?
      </p>
    </b-modal>
    <!--MODAL ADD/EDIT-->

    <!--MODAL DELETE-->
    <b-modal
      id="delete-users-confirm"
      ref="delete-users-confirm"
      size="sm"
      :title="$t('table.deactivateUsers')"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      :busy="isModalBusy"
      @ok.prevent="deleteUsers"
    >
      <p class="my-2">
        {{ $t('table.confirmDeactivateUsers') }} <span class="text-danger">{{ currentItem ? currentItem.email : '' }}</span>
        ?
      </p>
    </b-modal>
    <!--MODAL DELETE-->

    <!--MODAL RESTORE-->
    <b-modal
      id="restore-users-confirm"
      ref="restore-users-confirm"
      size="sm"
      :title="$t('table.restoreUsers')"
      :ok-title="$t('table.yes')"
      :cancel-title="$t('table.no')"
      auto-focus-button="ok"
      :busy="isModalBusy"
      @ok.prevent="restoreUsers"
    >
      <p class="my-2">
        {{ $t('table.confirmRestoreUsers') }} <span class="text-danger">{{ currentItem ? currentItem.email : '' }}</span>
        ?
      </p>
    </b-modal>
    <!--MODAL RESTORE-->
  </div>
</template>

<script>
import errorsHandler from '@/utils/errorsHandler';
import { mapGetters } from 'vuex';
import { email, maxLength, minLength, required, requiredIf } from 'vuelidate/lib/validators';
import MultiSelect from '@/components/MultiSelect';
import SpinnerLoader from '@/components/SpinnerLoader';
import { BTable } from 'bootstrap-vue';

export default {
  name: 'ModalUser',
  components: { MultiSelect, SpinnerLoader, BTable },
  props: {
    filterAgenciesList: { type: Array, default: undefined },
    canEdit: { type: Boolean },
    agency_id: { type: String, default: undefined },
    channel_id: { type: String, default: undefined },
    saleshouse_id: { type: String, default: undefined },
    modalName: { type: String, default: undefined },
    currentItem: { type: Object, default: undefined },
  },
  data() {
    return {
      busy: true,
      isModalBusy: false,
      name: '',
      login: '',
      email: '',
      password: '',
      saleshouse: '',
      isOpenPassword: true,
      role: '',
      showWithSpecialDiscounts: true,
      permissions: {
        agencies: {
          allowed: [],
          allowedList: [],
          allowedAll: true,
        },
        channels: {
          allowed: [],
          allowedList: [],
          allowedAll: true,
        },
        projects: {
          allowed: [],
          denied: [],
          allowedAll: true,
          allowedList: [],
          deniedList: [],
          agency: '',
        },
        advertisers: {
          allowed: [],
          denied: [],
          allowedAll: true,
          allowedList: [],
          deniedList: [],
          agency: '',
        },
        brands: {
          allowed: [],
          denied: [],
          allowedAll: true,
          allowedList: [],
          deniedList: [],
          class: '',
          classList: [],
          agency: '',
          advertisersList: [],
          advertiser: '',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      isThemeHeader: 'isTheme',
      agenciesList: 'getAgenciesList',
      channelsList: 'getChannelsList',
      salehousesList: 'getSalehousesList',
      roles: 'getRoles',
      isLocale: 'isLocale',
      projects: 'getProjectsList',
      advertisersList: 'getAdvertisersList',
      brandsList: 'getBrandsList',
    }),
  },
  validations() {
    if (this.modalName === 'add') {
      const defaultFields = {
        name: {
          required,
        },
        login: {
          required,
        },
        email: {
          email,
          required,
          minLength: minLength(5),
          maxLength: maxLength(254),
        },
        password: {
          required,
          minLength: minLength(8),
        },
        role: { required },
      };
      if (
        this.role?.name === 'Account manager saleshouse' ||
        this.role?.name === 'Chief' ||
        this.role?.name === 'Chief channel' ||
        this.role?.name === 'Chief saleshouse'
      )
        return {
          ...defaultFields,
          saleshouse: { required },
        };
      else if (this.role?.name === 'Account manager agency')
        return {
          ...defaultFields,
          permissions: {
            agencies: {
              allowed: { required },
            },
          },
        };
      else if (this.role?.name === 'Account manager channel')
        return {
          ...defaultFields,
          permissions: {
            channels: {
              allowed: { required },
            },
          },
        };
      else return defaultFields;
    } else {
      const defaultFields = {
        // IF EDIT
        name: {
          required,
        },
        login: {
          required,
        },
        email: {
          email,
          required,
          minLength: minLength(5),
          maxLength: maxLength(254),
        },
        password: {
          minLength: minLength(8),
        },
        role: { required },
      };
      if (
        this.role?.name === 'Account manager saleshouse' ||
        this.role?.name === 'Chief' ||
        this.role?.name === 'Chief channel' ||
        this.role?.name === 'Chief saleshouse'
      )
        return {
          ...defaultFields,
          saleshouse: { required },
        };
      else if (this.role?.name === 'Account manager agency')
        return {
          ...defaultFields,
          permissions: {
            agencies: {
              allowed: { required },
            },
          },
        };
      else if (this.role?.name === 'Account manager channel')
        return {
          ...defaultFields,
          permissions: {
            channels: {
              allowed: { required },
            },
          },
        };
      else return defaultFields;
    }
  },
  methods: {
    getTitle() {
      if (this.modalName === 'add') return this.$i18n.t('table.addNewUsers');
      else if (this.modalName === 'edit' && !this.canEdit) return this.name;
      else return this.$i18n.t('table.editUsers');
    },

    async loadProps() {
      this.busy = true;
      if (this.roles.length < 1) await this.$store.dispatch('GET_ROLES');
      this.permissions.agencies.allowedList = this.agenciesList.map((item) => ({ id: item.id, name: item.name }));
      this.permissions.channels.allowedList = this.channelsList.map((item) => ({ id: item.id, name: item.name }));
      if (this.modalName === 'add') {
        // If any query props - load it in select
        if (this.agency_id) {
          this.permissions.agencies.allowedList.forEach((el) => {
            if (el.id === +this.agency_id) {
              this.permissions.agencies.allowed.push({ id: el.id, name: el.name });
              el.rowSelected = true;
              this.permissions.agencies.allowedAll = false;
            }
          });
        }
        if (this.channel_id) {
          this.permissions.channels.allowedList.forEach((el) => {
            if (el.id === +this.channel_id) {
              this.permissions.channels.allowed.push({ id: el.id, name: el.name });
              el.rowSelected = true;
              this.permissions.channels.allowedAll = false;
            }
          });
        }
        if (this.saleshouse_id) {
          this.saleshouse = this.salehousesList.find((el) => el.id === +this.saleshouse_id);
        }
      } else if (this.modalName === 'edit' && this.currentItem) {
        let res = null;
        await this.$store.dispatch('GET_USERS_ID', {
          id: this.currentItem.id,
          include: { include: 'roles,permissions' },
          handler: (result) => (res = result),
          handlerError: (e) => {
            this.busy = false;
          },
        });
        if (!res) return; // exit loading user data on empty response (when error occurred)
        this.showWithSpecialDiscounts = res.permissions.some((e) => e.name === 'saleshouse_commission.include');
        this.id = res.id;
        this.name = res.name;
        this.login = res.login;
        this.email = res.email;
        this.saleshouse =
          res.access_permissions.access.saleshouse_ids && res.access_permissions.access.saleshouse_ids.length > 0
            ? this.salehousesList.find((el) => el.id === +res.access_permissions.access.saleshouse_ids[0])
            : '';
        this.role = res.roles ? res.roles[0] : '';

        // restore agencies access
        if (res.access_permissions.access.agency_ids === null) this.permissions.agencies.allowedAll = true;
        else this.permissions.agencies.allowedAll = false;
        if (res.access_permissions.access.agency_ids && res.access_permissions.access.agency_ids.length > 0) {
          this.permissions.agencies.allowedList.forEach((el) => {
            if (res.access_permissions.access.agency_ids.includes(el.id.toString())) {
              this.permissions.agencies.allowed.push({ id: el.id, name: el.name });
              el.rowSelected = true;
            }
          });
        }

        // restore channels access
        if (res.access_permissions.access.channel_ids === null) this.permissions.channels.allowedAll = true;
        else this.permissions.channels.allowedAll = false;
        if (res.access_permissions.access.channel_ids && res.access_permissions.access.channel_ids.length > 0) {
          this.permissions.channels.allowedList.forEach((el) => {
            if (res.access_permissions.access.channel_ids.includes(el.id.toString())) {
              this.permissions.channels.allowed.push({ id: el.id, name: el.name });
              el.rowSelected = true;
            }
          });
        }

        // restore advertisers access
        if (res.access_permissions.access.advertiser_ids === null) this.permissions.advertisers.allowedAll = true;
        else this.permissions.advertisers.allowedAll = false;

        if (
          (res.access_permissions.access.advertiser_ids && res.access_permissions.access.advertiser_ids.length > 0) ||
          (res.access_permissions.deny.advertiser_ids && res.access_permissions.deny.advertiser_ids.length > 0)
        ) {
          await this.$store.dispatch('GET_ADVERTISERS', { per_page: 2000 });
          // Restore check marks and selected
          if (res.access_permissions.access.advertiser_ids && res.access_permissions.access.advertiser_ids.length > 0) {
            this.advertisersList.forEach((el) => {
              if (res.access_permissions.access.advertiser_ids.includes(el.id.toString())) {
                this.permissions.advertisers.allowed.push({ id: el.id, name: el.name });
              }
            });
          }
          if (res.access_permissions.deny.advertiser_ids && res.access_permissions.deny.advertiser_ids.length > 0) {
            this.advertisersList.forEach((el) => {
              if (res.access_permissions.deny.advertiser_ids.includes(el.id.toString())) {
                this.permissions.advertisers.denied.push({ id: el.id, name: el.name });
              }
            });
          }
        }

        // restore brands access
        if (res.access_permissions.access.brand_ids === null) this.permissions.brands.allowedAll = true;
        else this.permissions.brands.allowedAll = false;

        if (
          (res.access_permissions.access.brand_ids && res.access_permissions.access.brand_ids.length > 0) ||
          (res.access_permissions.deny.brand_ids && res.access_permissions.deny.brand_ids.length > 0)
        ) {
          await this.$store.dispatch('GET_BRANDS', { per_page: 5000 });
          // Restore check marks and selected
          if (res.access_permissions.access.brand_ids && res.access_permissions.access.brand_ids.length > 0) {
            this.brandsList.forEach((el) => {
              if (res.access_permissions.access.brand_ids.includes(el.id.toString())) {
                this.permissions.brands.allowed.push({ id: el.id, name: el.name });
              }
            });
          }
          if (res.access_permissions.deny.brand_ids && res.access_permissions.deny.brand_ids.length > 0) {
            this.brandsList.forEach((el) => {
              if (res.access_permissions.deny.brand_ids.includes(el.id.toString())) {
                this.permissions.brands.denied.push({ id: el.id, name: el.name });
              }
            });
          }
        }

        // restore projects access
        if (res.access_permissions.access.project_ids === null) this.permissions.projects.allowedAll = true;
        else this.permissions.projects.allowedAll = false;

        if (
          (res.access_permissions.access.project_ids && res.access_permissions.access.project_ids.length > 0) ||
          (res.access_permissions.deny.project_ids && res.access_permissions.deny.project_ids.length > 0)
        ) {
          await this.$store.dispatch('GET_PROJECTS', { per_page: 5000, sort: '-year_id,name' });
          // Restore check marks and selected
          if (res.access_permissions.access.project_ids && res.access_permissions.access.project_ids.length > 0) {
            this.projects.forEach((el) => {
              if (res.access_permissions.access.project_ids.includes(el.id.toString())) {
                this.permissions.projects.allowed.push({ id: el.id, name: el.name });
              }
            });
          }
          if (res.access_permissions.deny.project_ids && res.access_permissions.deny.project_ids.length > 0) {
            this.projects.forEach((el) => {
              if (res.access_permissions.deny.project_ids.includes(el.id.toString())) {
                this.permissions.projects.denied.push({ id: el.id, name: el.name });
              }
            });
          }
        }
      }
      this.busy = false;
    },

    clearFields() {
      this.name = '';
      this.login = '';
      this.email = '';
      this.password = '';
      this.saleshouse = '';
      this.id = '';
      this.isOpenPassword = true;
      this.role = '';
      this.showWithSpecialDiscounts = true;
      this.permissions.agencies = {
        allowed: [],
        allowedList: [],
        allowedAll: true,
      };
      this.permissions.channels = {
        allowed: [],
        allowedList: [],
        allowedAll: true,
      };
      this.permissions.projects = {
        allowed: [],
        denied: [],
        allowedAll: true,
        allowedList: [],
        deniedList: [],
        agency: '',
      };
      this.permissions.advertisers = {
        allowed: [],
        denied: [],
        allowedAll: true,
        allowedList: [],
        deniedList: [],
        agency: '',
      };
      this.permissions.brands = {
        allowed: [],
        denied: [],
        allowedAll: true,
        allowedList: [],
        deniedList: [],
        class: '',
        classList: [],
        agency: '',
        advertisersList: [],
        advertiser: '',
      };
    },

    hideModalAddEditUsers() {
      this.$refs['add-edit-users'].hide();
    },
    showModalUsersConfirm() {
      this.$refs['add-users-confirm'].show();
    },
    hideModalAddUsersConfirm() {
      this.$refs['add-users-confirm'].hide();
    },
    async addEditUsers() {
      this.isModalBusy = true;
      const accessAgencies = this.permissions.agencies.allowedAll ? null : this.permissions.agencies.allowed.map((el) => el.id);
      const accessChannels = this.permissions.channels.allowedAll ? null : this.permissions.channels.allowed.map((el) => el.id);
      const accessProjects = this.permissions.projects.allowedAll ? null : this.permissions.projects.allowed.map((el) => el.id);
      const accessAdvertisers = this.permissions.advertisers.allowedAll ? null : this.permissions.advertisers.allowed.map((el) => el.id);
      const accessBrands = this.permissions.brands.allowedAll ? null : this.permissions.brands.allowed.map((el) => el.id);
      const deniedProjects = this.permissions.projects.denied.map((el) => el.id);
      const deniedAdvertisers = this.permissions.advertisers.denied.map((el) => el.id);
      const deniedBrands = this.permissions.brands.denied.map((el) => el.id);
      // Chief SH doesn't need this, because he has a toggle in reports
      const permissions =
        this.role.name === 'Chief' || this.role.name === 'Chief channel'
          ? {
              saleshouse_commission: {
                include: this.showWithSpecialDiscounts,
              },
            }
          : undefined;

      const formData = {
        login: this.login,
        password: this.password ? this.password : undefined,
        email: this.email,
        role_id: this.role?.id,
        name: this.name,
        permissions: permissions,
        access_permissions: {
          access: {
            saleshouse_ids:
              (this.saleshouse && this.role?.name === 'Account manager saleshouse') ||
              this.role?.name === 'Chief' ||
              this.role?.name === 'Chief channel' ||
              this.role?.name === 'Chief saleshouse'
                ? [this.saleshouse?.id]
                : undefined,
            agency_ids:
              this.role?.name === 'Account manager agency' ||
              this.role?.name === 'Chief' ||
              this.role?.name === 'Chief channel' ||
              this.role?.name === 'Chief saleshouse' ||
              this.role?.name === 'Account manager saleshouse'
                ? accessAgencies
                : undefined,
            channel_ids:
              this.role?.name === 'Account manager channel' ||
              this.role?.name === 'Chief' ||
              this.role?.name === 'Chief channel' ||
              this.role?.name === 'Chief saleshouse' ||
              this.role?.name === 'Account manager agency'
                ? accessChannels
                : undefined,
            project_ids: accessProjects,
            advertiser_ids: accessAdvertisers,
            brand_ids: accessBrands,
          },
          deny: {
            project_ids: deniedProjects,
            advertiser_ids: deniedAdvertisers,
            brand_ids: deniedBrands,
          },
        },
      };
      if (this.modalName === 'add') {
        await this.$store.dispatch('POST_USERS', {
          formData,
          handler: (res) => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.addUsers'),
              text: this.email,
            });
            this.$emit('updateUsers');
            this.hideModalAddEditUsers();
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      } else if (this.modalName === 'edit') {
        const id = this.currentItem.id;
        await this.$store.dispatch('PUT_USERS', {
          id,
          formData,
          handler: () => {
            this.$notify({
              type: 'success',
              title: this.$i18n.t('alert.editUsers'),
              text: this.email,
            });
            this.$emit('updateUsers');
            this.hideModalAddEditUsers();
          },
          handlerError: (errors) => {
            errorsHandler(errors, this.$notify);
          },
        });
      }

      this.hideModalAddUsersConfirm();
      this.isModalBusy = false;
    },

    // modal delete
    hideModalDeleteUsersConfirm() {
      this.$refs['delete-users-confirm'].hide();
    },
    async deleteUsers() {
      this.isModalBusy = true;
      const formData = this.currentItem.id;
      await this.$store.dispatch('DELETE_USERS_ID', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.deactivatedUsers'),
            text: this.currentItem.email,
          });
          this.$emit('updateUsers');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.hideModalDeleteUsersConfirm();
      this.isModalBusy = false;
    },

    // modal restore
    hideModalRestoreUsersConfirm() {
      this.$refs['restore-users-confirm'].hide();
    },
    async restoreUsers() {
      this.isModalBusy = true;
      const formData = this.currentItem.id;
      await this.$store.dispatch('PUT_USERS_RESTORE', {
        formData,
        handler: () => {
          this.$notify({
            type: 'success',
            title: this.$i18n.t('alert.restoredUsers'),
            text: this.currentItem.email,
          });
          this.$emit('updateUsers');
        },
        handlerError: (errors) => {
          errorsHandler(errors, this.$notify);
        },
      });
      this.hideModalRestoreUsersConfirm();
      this.isModalBusy = false;
    },

    checkIfValidThenEnter() {
      if (!this.$v.$invalid && !this.isModalBusy) this.showModalUsersConfirm();
    },

    // ADVERTISERS
    async loadAllowedAdvertisers() {
      await this.$store.dispatch('GET_ADVERTISERS', {
        per_page: 2000,
        'filter[agency_id]': this.permissions.advertisers.agency?.id,
      });
      // Create unlinked arrays
      this.permissions.advertisers.allowedList = this.advertisersList.map((item) => ({ id: item.id, name: item.name }));
      this.permissions.advertisers.deniedList = this.advertisersList.map((item) => ({ id: item.id, name: item.name }));
      // Restore check marks
      if (this.permissions.advertisers.allowed.length > 0) {
        this.permissions.advertisers.allowedList.forEach((el) => {
          if (this.permissions.advertisers.allowed.some((s) => s.id === el.id)) el.rowSelected = true;
        });
      }
      if (this.permissions.advertisers.denied.length > 0) {
        this.permissions.advertisers.deniedList.forEach((el) => {
          if (this.permissions.advertisers.denied.some((s) => s.id === el.id)) el.rowSelected = true;
        });
      }
    },

    selectAllowedAdvertiser(item, index, event) {
      if (this.canEdit) {
        let indexOfExist = 0;
        const ifExist = this.permissions.advertisers.allowed.some((el, index) => {
          if (el.id === item.id) indexOfExist = index;
          return el.id === item.id;
        });
        if (ifExist) {
          this.permissions.advertisers.allowed.splice(indexOfExist, 1);
        } else {
          this.permissions.advertisers.allowed.push({ id: item.id, name: item.name });
        }
        this.permissions.advertisers.allowedList[index].rowSelected = !this.permissions.advertisers.allowedList[index].rowSelected;
        if (this.permissions.advertisers.allowedAll) this.permissions.advertisers.allowedAll = false; //Toggle All to off
      }
    },
    selectDeniedAdvertiser(item, index, event) {
      if (this.canEdit) {
        let indexOfExist = 0;
        const ifExist = this.permissions.advertisers.denied.some((el, index) => {
          if (el.id === item.id) indexOfExist = index;
          return el.id === item.id;
        });
        if (ifExist) {
          this.permissions.advertisers.denied.splice(indexOfExist, 1);
        } else {
          this.permissions.advertisers.denied.push({ id: item.id, name: item.name });
        }
        this.permissions.advertisers.deniedList[index].rowSelected = !this.permissions.advertisers.deniedList[index].rowSelected;
      }
    },

    // BRANDS
    async loadAdvertisersForBrand() {
      await this.$store.dispatch('GET_ADVERTISERS', {
        per_page: 2000,
        'filter[agency_id]': this.permissions.brands.agency?.id,
      });
      this.permissions.brands.advertisersList = [{ name: this.$i18n.t('table.all'), id: null }, ...this.advertisersList];
    },

    async loadBrands() {
      await this.$store.dispatch('GET_BRANDS', {
        per_page: 2000,
        'filter[advertiser_id]': this.permissions.brands.advertiser?.id,
      });
      // Create unlinked arrays
      this.permissions.brands.allowedList = this.brandsList.map((item) => ({ id: item.id, name: item.name }));
      this.permissions.brands.deniedList = this.brandsList.map((item) => ({ id: item.id, name: item.name }));
      // Restore check marks
      if (this.permissions.brands.allowed.length > 0) {
        this.permissions.brands.allowedList.forEach((el) => {
          if (this.permissions.brands.allowed.some((s) => s.id === el.id)) el.rowSelected = true;
        });
      }
      if (this.permissions.brands.denied.length > 0) {
        this.permissions.brands.deniedList.forEach((el) => {
          if (this.permissions.brands.denied.some((s) => s.id === el.id)) el.rowSelected = true;
        });
      }
    },

    selectAllowedBrand(item, index, event) {
      if (this.canEdit) {
        let indexOfExist = 0;
        const ifExist = this.permissions.brands.allowed.some((el, index) => {
          if (el.id === item.id) indexOfExist = index;
          return el.id === item.id;
        });
        if (ifExist) {
          this.permissions.brands.allowed.splice(indexOfExist, 1);
        } else {
          this.permissions.brands.allowed.push({ id: item.id, name: item.name });
        }
        this.permissions.brands.allowedList[index].rowSelected = !this.permissions.brands.allowedList[index].rowSelected;
        if (this.permissions.brands.allowedAll) this.permissions.brands.allowedAll = false; //Toggle All to off
      }
    },
    selectDeniedBrand(item, index, event) {
      if (this.canEdit) {
        let indexOfExist = 0;
        const ifExist = this.permissions.brands.denied.some((el, index) => {
          if (el.id === item.id) indexOfExist = index;
          return el.id === item.id;
        });
        if (ifExist) {
          this.permissions.brands.denied.splice(indexOfExist, 1);
        } else {
          this.permissions.brands.denied.push({ id: item.id, name: item.name });
        }
        this.permissions.brands.deniedList[index].rowSelected = !this.permissions.brands.deniedList[index].rowSelected;
      }
    },

    // PROJECTS
    async loadProjects() {
      await this.$store.dispatch('GET_PROJECTS', {
        per_page: 2000,
        'filter[agency_id]': this.permissions.projects.agency?.id,
        sort: '-year_id,name',
      });
      // Create unlinked arrays
      this.permissions.projects.allowedList = this.projects.map((item) => ({ id: item.id, name: item.name }));
      this.permissions.projects.deniedList = this.projects.map((item) => ({ id: item.id, name: item.name }));
      // Restore check marks
      if (this.permissions.projects.allowed.length > 0) {
        this.permissions.projects.allowedList.forEach((el) => {
          if (this.permissions.projects.allowed.some((s) => s.id === el.id)) el.rowSelected = true;
        });
      }
      if (this.permissions.projects.denied.length > 0) {
        this.permissions.projects.deniedList.forEach((el) => {
          if (this.permissions.projects.denied.some((s) => s.id === el.id)) el.rowSelected = true;
        });
      }
    },
    selectAllowedProject(item, index, event) {
      if (this.canEdit) {
        let indexOfExist = 0;
        const ifExist = this.permissions.projects.allowed.some((el, index) => {
          if (el.id === item.id) indexOfExist = index;
          return el.id === item.id;
        });
        if (ifExist) {
          this.permissions.projects.allowed.splice(indexOfExist, 1);
        } else {
          this.permissions.projects.allowed.push({ id: item.id, name: item.name });
        }
        this.permissions.projects.allowedList[index].rowSelected = !this.permissions.projects.allowedList[index].rowSelected;
        if (this.permissions.projects.allowedAll) this.permissions.projects.allowedAll = false; //Toggle All to off
      }
    },
    selectDeniedProject(item, index, event) {
      if (this.canEdit) {
        let indexOfExist = 0;
        const ifExist = this.permissions.projects.denied.some((el, index) => {
          if (el.id === item.id) indexOfExist = index;
          return el.id === item.id;
        });
        if (ifExist) {
          this.permissions.projects.denied.splice(indexOfExist, 1);
        } else {
          this.permissions.projects.denied.push({ id: item.id, name: item.name });
        }
        this.permissions.projects.deniedList[index].rowSelected = !this.permissions.projects.deniedList[index].rowSelected;
      }
    },

    selectAllowedAgency(item, index, event) {
      if (this.canEdit) {
        let indexOfExist = 0;
        const ifExist = this.permissions.agencies.allowed.some((el, index) => {
          if (el.id === item.id) indexOfExist = index;
          return el.id === item.id;
        });
        if (ifExist) {
          this.permissions.agencies.allowed.splice(indexOfExist, 1);
        } else {
          this.permissions.agencies.allowed.push({ id: item.id, name: item.name });
        }
        this.permissions.agencies.allowedList[index].rowSelected = !this.permissions.agencies.allowedList[index].rowSelected;
        if (this.permissions.agencies.allowedAll) this.permissions.agencies.allowedAll = false; //Toggle All to off
      }
    },

    selectAllowedChannel(item, index, event) {
      let indexOfExist = 0;
      const ifExist = this.permissions.channels.allowed.some((el, index) => {
        if (el.id === item.id) indexOfExist = index;
        return el.id === item.id;
      });
      if (ifExist) {
        this.permissions.channels.allowed.splice(indexOfExist, 1);
      } else {
        this.permissions.channels.allowed.push({ id: item.id, name: item.name });
      }
      this.permissions.channels.allowedList[index].rowSelected = !this.permissions.channels.allowedList[index].rowSelected;
      if (this.permissions.channels.allowedAll) this.permissions.channels.allowedAll = false; //Toggle All to off
    },

    clearInEnd() {
      this.clearFields;
      this.busy = true;
      this.$emit('clearModalProps');
      this.$store.commit('clearProjects');
      this.$store.commit('clearAdvertisersList');
      this.$store.commit('clearBrandsList');
    },

    toggleAllowedAll(entity, list) {
      if (entity.allowedAll) {
        entity.allowed = [];
        if (entity.allowedList.length > 0) entity.allowedList = list?.map((item) => ({ id: item.id, name: item.name })) || [];
      }
    },
  },
};
</script>

<style lang="sass">
.permission-table
  min-height: 200px
  max-height: 200px
  overflow: auto
  td
    padding: 3px !important

[data-color-scheme="dark"] .modal-content
  div.table-sm.small.permission-table,
  hr
    border-color: #4c5358 !important
</style>
